<template>
  <div class="database">
    <div class="searchBox">
      <el-select
        class="select-search"
        v-model="selectValue"
        placeholder="请选择"
      >
        <el-option
          v-for="item in tabList"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        :placeholder="i18nData.placehold"
        v-model="search"
        class="input-search"
        @keyup.enter.native="toSearch"
      >
        <el-button
          class="button-search"
          slot="append"
          icon="el-icon-search"
          @click="toSearch"
        ></el-button>
      </el-input>
    </div>
    <!-- <div class="menuBox">
      <el-menu
        :default-active="databaseBar"
        class="el-menu-demo elmenu"
        mode="horizontal"
        @select="handleSelect"
        background-color="#0B6FBC"
        text-color="#dddddd"
        active-text-color="#fff"
      >
        <el-menu-item
          class="elmenuitem"
          v-for="item in tabList"
          :key="item.id"
          :index="String(item.id)"
        >
          {{ item.title }}
        </el-menu-item>
      </el-menu>
    </div> -->
    <el-table
      :data="tableData"
      style="width: 100%"
      :header-row-style="{ color: '#282728', 'text-align': 'right' }"
      empty-text=" "
      height="50"
    >
      <el-table-column prop="date" label="中文名称"> </el-table-column>
      <el-table-column prop="name" label="英文名称"> </el-table-column>
      <el-table-column prop="address" label="CAS号"> </el-table-column>
      <el-table-column prop="address" label="英文缩写"> </el-table-column>
      <el-table-column prop="address" label="分子式"> </el-table-column>
      <el-table-column prop="address" label="药剂学类别"> </el-table-column>
    </el-table>
    <el-collapse v-model="activeName">
      <el-collapse-item
        v-for="item in tabList"
        :key="item.id"
        :index="String(item.id)"
        :name="String(item.id)"
      >
        <template slot="title">
          <i class="el-icon-s-unfold iconSize"></i>{{ item.title }}（{{
            item.seotitle
          }}）
        </template>
        <div class="databaseBox">
          <databaseList :archives-list="item.archivesList"></databaseList>
          <div class="noneBox" v-if="!item.archivesList.length > 0">
            <!-- <el-image
              style="width: 20vh; height: 20vh;"
              :src="require('./../../public/img/mycollection/img_none.png')"
              fit="cover"
            >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image> -->
            <!-- <img src="./../../public/img/mycollection/img_none.png" alt=""> -->
            <span>该分类暂无内容</span>
            <!-- <span
              >联系电话 <el-link type="primary">{{ phone }}</el-link></span
            > -->
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <!-- <div class="pagination" v-show="archivesList.length > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import databaseList from "./../components/databaseList.vue";
import { toTop, search } from "./../common/top.mixin.js";

export default {
  name: "database",
  mixins: [toTop, search],
  components: {
    databaseList
  },
  data() {
    return {
      databaseBar: "",
      total: 100,
      pageSize: 10,
      currentPage: 1,
      archivesList: [],
      channel: {},
      bannerList: [],
      activeName: ["1","2","3","4","5"],
      selectValue: "",
      tableData:[],
      tabList: [
        {
          id: 24,
          title: "其他",
          seotitle: "Other",
          items: 0,
          image: "https://admin.pku-epquangeno.com/assets/addons/cms/img/noimage.jpg",
          archivesList: []
        },
        {
          id: 6,
          title: "多环芳烃",
          seotitle: "PAHs",
          items: 8,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/592d14cfeaa2ea69c46088786ecf14ee.png",
          archivesList: []
        },
        {
          id: 5,
          title: "药物及个人护理品",
          seotitle: "PPCPs",
          items: 7,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/fdc291ead730e1513b3f8f3cc79f5f26.png",
          archivesList: []
        },
        {
          id: 4,
          title: "全氟辛酸类",
          seotitle: "PFASs",
          items: 5,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/a05dc8ab6dadc5ec62d07ac1132962a6.png",
          archivesList: []
        },
        {
          id: 3,
          title: "挥发性有机污染物",
          seotitle: "VOCs",
          items: 5,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/69eeaeaff3c15781c8536d600e81d2ba.png",
          archivesList: []
        }
      ]
    };
  },
  computed: {
    phone() {
      console.log(this.$store.state.vuex_config)
      if (this.$store.state.vuex_config.config) {
        return this.$store.state.vuex_config.config.phone_number;
      }
      return '';
    }
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    let databaseBar = this.$route.query.databaseBar;
    if (databaseBar) {
      this.databaseBar = databaseBar;
    }
    this.getCategory();
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.databaseBar = key;
      this.currentPage = 1;
      this.getArchives();
    },
    // 分页处理
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getArchives();
      this.backTop();
    },
    async getArchives(params) {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        // let params = {
        //   page: this.currentPage,
        //   channel: this.databaseBar,
        //   model: 2,
        //   menu_index: 1
        // };
        let res = await this.$api.https.getArchives(params);
        console.log(this.tabList, res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { pageList, channel } = res.data;
        this.channel = channel;
        this.total = pageList.total;
        this.archivesList = [...pageList.data];
        this.tabList.forEach(item => {
          if (item.id == params.channel) {
            this.$set(
              item,
              "archivesList",
              pageList.data.filter((item, index) => index < 5)
            );
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getCategory() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          channel: "",
          menu_index: ""
        };
        let res = await this.$api.https.getCategory(params);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        if (res.code == 1) {
          const tabList = res.data.list;
          tabList.sort((a, b) => (a.id > b.id ? 1 : -1));
          this.tabList = tabList;
          if (this.databaseBar) {
            this.activeName = String(this.databaseBar);
          } else {
            this.activeName = [];
            tabList.forEach(item => {
              this.activeName.push(String(item.id));
            });
          }

          tabList.forEach(item => {
            let params = {
              page: this.currentPage,
              channel: item.id,
              model: 2,
              menu_index: 1
            };
            this.getArchives(params);
          });
        } else {
          this.$message({
            message: res.msg
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-button {
  color: #282828 !important;
}
.database {
  background-color: #ffffff;
  line-height: 24px;
  text-align: left;
  padding: 20px 5% 5%;
  .iconSize {
    font-size: 20px;
    margin-right: 10px;
  }
  .searchBox {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .select-search {
      width: 200px;
      ::v-deep .el-input__inner {
        border-radius: 0px;
      }
    }
    .input-search {
      width: 400px;
      height: 30px;
      ::v-deep .el-input__inner {
        border-radius: 0px;
      }
    }
    .button-search {
      background-color: #fff;
    }
  }
  .menuBox {
    .elmenu {
      display: flex;
      justify-content: space-between;
      ::v-deep .el-menu-item {
        flex: 1;
        text-align: center;
      }
    }
    ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
      font-weight: bold;
    }
  }
  .databaseBox {
    padding: 0 0;
    .noneBox {
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 20vh;
        height: 20vh;
      }
      span {
        margin-top: 10px;
        color: #a1a1a1;
        font-size: 14px;
        // border-bottom: 1px solid #dcdfe6;
      }
    }
  }
}
</style>
